.presentation {
	height: calc(100vh - 60px);
	max-height: 900px;
	text-align: center;
	position: relative;
	background: linear-gradient(0, #fff, $card-color 90%);

	&_container {
		height: 100%;
		width: 80%;
		max-width: $max-width-screen;
		margin: auto;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 2;
	}

	&_details {
		max-width: 450px;
	}

	.network {
		position: absolute;
		right: 0;
		bottom: 60px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&-items {
			width: 30px;
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	h2 {
		color: $third-color;
		position: relative;

		&::after {
			content: "";
			height: 3px;
			width: 100%;
			position: absolute;
			bottom: -17px;
			left: 50%;
			transform: translateX(-50%);
			background: $second-color;
			border-radius: 6px;
		}
	}

	p {
		position: relative;

		&::after {
			content: "";
			height: 3px;
			width: 50%;
			position: absolute;
			bottom: -20px;
			left: 50%;
			transform: translateX(-50%);
			background: $second-color;
			border-radius: 6px;
		}
	}
}

/* Placement élément gémétrique*/
.geometric-element {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	overflow: hidden;
}

.zigzag {
	position: absolute;
	top: 2vh;
	left: 15vw;
	width: 40vw;
	max-width: 500px;

	#black {
		animation: zigzag-black 3s infinite linear both;
	}

	#yellow {
		animation: zigzag-yellow 3s infinite linear both;
	}
}

.square {
	position: absolute;
	width: 15vw;
	top: 17vh;
	left: 25vw;
	max-width: 200px;

	.sens-horaire {
		transform-origin: center;
		animation: carre-sens-horaire 8s infinite ease-in-out both;
	}

	.sens-anti-horaire {
		transform-origin: center;
		animation: carre-sens-anti-horaire 8s infinite ease-in-out both;
	}
}

.square-points {
	position: absolute;
	width: 20vw;
	bottom: 4vh;
	right: 16vw;
	max-width: 300px;

	.sens-horaire {
		transform-origin: center;
		animation: carre-sens-horaire-slow 8s infinite linear both;
	}
}

.two-circles-traits {
	position: absolute;
	width: 30vw;
	top: 60vh;
	left: 0;
	max-width: 400px;
	transform: translateY(-50%);

	#half-circle-1 {
		animation: half-circle 3s infinite ease-in-out both;
	}

	#half-circle-2 {
		animation: half-circle 3s infinite 0.3s ease-in-out both;
	}
}

.two-half-circle {
	position: absolute;
	width: 13vw;
	top: 2vh;
	right: 23vw;
	max-width: 200px;

	#shadow {
		animation: two-half-circle 3s infinite 0.3s ease-in-out both;
	}
}

.double-circle {
	position: absolute;
	width: 13vw;
	top: 23vh;
	right: 8vw;
	max-width: 220px;

	#circle-rotation {
		transform-origin: 33% 67%;
		animation: circle-rotate 3s infinite 0.3s linear
	}
}