.entete {
	background: $primary-color;
	height: 60px;
	display: flex;
	flex-wrap: wrap;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 3;

	&_container {
		margin: auto;
		width: 80%;
		max-width: $max-width-screen;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	&_items {
		display: inline-block;
		padding: 10px;
		color: white;
		margin-left: 5px;
		font-size: 1.6rem;
		transition: color $transition-duration;

		&:hover {
			color: $second-color;
		}

		&:last-child {
			margin-left: 0;
		}
	}

	&_jour-nuit {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		span {
			height: 16px;
			width: 40px;
			background: white;
			border-radius: 20px;
			display: inline-block;
			margin: 0 10px;
			position: relative;

			&:before {
				content: '';
				height: 14px;
				width: 14px;
				background: $second-color;
				position: absolute;
				top: 50%;
				left: 2px;
				transform: translateY(-50%);
				border-radius: 12px;
				transition: $transition-duration;
			}
		}

		svg {
			fill: white;
			width: auto;
			height: 30px;
		}
	}

	&_mobile-menu {
		display: none
	}
}

.entete_project {
	display: flex;
    flex-wrap: wrap;
	justify-content: space-between;
}