@charset "UTF-8";
/*
 * 1 - Header
 * 2 - Header présentation
 * 3 - À propos
 * 4 - Compétences
 * 5 - Réalisations
 * 6 - Contact
 * 7 - Footer
 * 8 - Page des projets et réalisations
 * 9 - Composants
 * 10 - Responsive
 * 11 - Mode nuit
 * 12 - Animations
 */
/*Open Dyslexic*/
/*@font-face {
	font-family: 'Open Dyslexic';
	src: local('./font/open_dyslexic'),
		url('../../assets/font/open_dyslexic/OpenDyslexic-Regular.otf') format('opentype');
	font-weight: normal;
}
@font-face {
	font-family: 'Open Dyslexic';
	src: local('./font/open_dyslexic'),
		url('../../assets/font/open_dyslexic/OpenDyslexic-Bold.otf') format('opentype');
	font-weight: bold;
}
@font-face {
	font-family: 'Open Dyslexic';
	src: local('./font/open_dyslexic'),
		url('../../assets/font/open_dyslexic/OpenDyslexic-BoldItalic.otf') format('opentype');
	font-weight: bold;
	font-style: italic;
}*/
/*Luciole*/
/*@font-face {
	font-family: 'Luciole';
	src: local('./font/luciole'),
		url('../../assets/font/luciole/Luciole-Regular.ttf') format('truetype');
	font-weight: normal;
}
@font-face {
	font-family: 'Luciole';
	src: local('./font/luciole'),
		url('../../assets/font/luciole/Luciole-Regular-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'Luciole';
	src: local('./font/luciole'),
		url('../../assets/font/luciole/Luciole-Bold.ttf') format('truetype');
	font-weight: bold;
}
@font-face {
	font-family: 'Luciole';
	src: local('./font/luciole'),
		url('../../assets/font/luciole/Luciole-Bold-Italic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}*/
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

main {
  overflow: hidden;
}

li,
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

img, svg {
  width: 100%;
  height: auto;
}

svg {
  overflow: visible;
}

a,
a:hover {
  text-decoration: none;
  color: #000000;
}

section:not(.presentation) {
  margin: 80px auto;
}
section:not(.presentation):last-child {
  margin-bottom: 0;
}

.corps_page--interieur {
  max-width: 1180px;
  width: 80%;
  margin: auto;
  padding: 50px 0;
}
.corps_page--interieur.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

body {
  line-height: 1.7em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter Tight", sans-serif;
  font-weight: normal;
  margin: 3rem auto;
  line-height: 1.2em;
}

h1 {
  font-size: 3.6rem;
}

#entete_page {
  color: white;
  font-size: 2.8em;
  margin: 0;
}

h2 {
  font-size: 2.6rem;
}

h3 {
  font-size: 2.4rem;
}

h4,
h5,
h6 {
  font-size: 1.6rem;
}

p, label, li {
  font-size: 1.6rem;
  font-family: "Inter Tight", sans-serif;
  font-weight: 200;
  line-height: 1.5em;
}

a.link {
  background: linear-gradient(0deg, #ffaf00 0%, #ffaf00 65%, rgba(255, 255, 255, 0) 65%);
}

.titre {
  color: #14213d;
  position: relative;
  width: fit-content;
  z-index: 2;
}
.titre:after {
  content: "";
  display: block;
  background: #ffaf00;
  width: 110%;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  position: absolute;
  z-index: -1;
  bottom: 0;
}

/*** 1 - Header ***/
.entete {
  background: #000000;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
}
.entete_container {
  margin: auto;
  width: 80%;
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.entete_items {
  display: inline-block;
  padding: 10px;
  color: white;
  margin-left: 5px;
  font-size: 1.6rem;
  transition: color 0.5s;
}
.entete_items:hover {
  color: #ffaf00;
}
.entete_items:last-child {
  margin-left: 0;
}
.entete_jour-nuit {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.entete_jour-nuit span {
  height: 16px;
  width: 40px;
  background: white;
  border-radius: 20px;
  display: inline-block;
  margin: 0 10px;
  position: relative;
}
.entete_jour-nuit span:before {
  content: "";
  height: 14px;
  width: 14px;
  background: #ffaf00;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  border-radius: 12px;
  transition: 0.5s;
}
.entete_jour-nuit svg {
  fill: white;
  width: auto;
  height: 30px;
}
.entete_mobile-menu {
  display: none;
}

.entete_project {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.close.button, .close.button--second, .close.button--accessibility {
  position: absolute;
  top: 0;
  right: 0;
}

body.no-animation svg * {
  animation: none !important;
}

body.dys-font p {
  font-family: "Inter Tight", sans-serif;
}
body.dys-font h1 {
  font-family: "Inter Tight", sans-serif;
}
body.dys-font h2 {
  font-family: "Inter Tight", sans-serif;
}
body.dys-font h3 {
  font-family: "Inter Tight", sans-serif;
}
body.dys-font li {
  font-family: "Inter Tight", sans-serif;
}
body.dys-font input {
  font-family: "Inter Tight", sans-serif;
}
body.dys-font textarea {
  font-family: "Inter Tight", sans-serif;
}

body.partially-sighted-font * p {
  font-family: "Inter Tight", sans-serif;
}
body.partially-sighted-font * h1 {
  font-family: "Inter Tight", sans-serif;
}
body.partially-sighted-font * h2 {
  font-family: "Inter Tight", sans-serif;
}
body.partially-sighted-font * h3 {
  font-family: "Inter Tight", sans-serif;
}
body.partially-sighted-font * li {
  font-family: "Inter Tight", sans-serif;
}
body.partially-sighted-font * input {
  font-family: "Inter Tight", sans-serif;
}
body.partially-sighted-font * textarea {
  font-family: "Inter Tight", sans-serif;
}

.disable {
  color: #8b8b8b;
  text-decoration: line-through;
}
.disable input {
  pointer-events: none;
}

.ReactModalPortal {
  position: fixed;
  z-index: 10;
}

/*** 2 - Header présentation ***/
.presentation {
  height: calc(100vh - 60px);
  max-height: 900px;
  text-align: center;
  position: relative;
  background: linear-gradient(0, #fff, #f5f5f5 90%);
}
.presentation_container {
  height: 100%;
  width: 80%;
  max-width: 1180px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.presentation_details {
  max-width: 450px;
}
.presentation .network {
  position: absolute;
  right: 0;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.presentation .network-items {
  width: 30px;
  margin-bottom: 20px;
}
.presentation .network-items:last-child {
  margin-bottom: 0;
}
.presentation h2 {
  color: #14213d;
  position: relative;
}
.presentation h2::after {
  content: "";
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: -17px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffaf00;
  border-radius: 6px;
}
.presentation p {
  position: relative;
}
.presentation p::after {
  content: "";
  height: 3px;
  width: 50%;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffaf00;
  border-radius: 6px;
}

/* Placement élément gémétrique*/
.geometric-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.zigzag {
  position: absolute;
  top: 2vh;
  left: 15vw;
  width: 40vw;
  max-width: 500px;
}
.zigzag #black {
  animation: zigzag-black 3s infinite linear both;
}
.zigzag #yellow {
  animation: zigzag-yellow 3s infinite linear both;
}

.square {
  position: absolute;
  width: 15vw;
  top: 17vh;
  left: 25vw;
  max-width: 200px;
}
.square .sens-horaire {
  transform-origin: center;
  animation: carre-sens-horaire 8s infinite ease-in-out both;
}
.square .sens-anti-horaire {
  transform-origin: center;
  animation: carre-sens-anti-horaire 8s infinite ease-in-out both;
}

.square-points {
  position: absolute;
  width: 20vw;
  bottom: 4vh;
  right: 16vw;
  max-width: 300px;
}
.square-points .sens-horaire {
  transform-origin: center;
  animation: carre-sens-horaire-slow 8s infinite linear both;
}

.two-circles-traits {
  position: absolute;
  width: 30vw;
  top: 60vh;
  left: 0;
  max-width: 400px;
  transform: translateY(-50%);
}
.two-circles-traits #half-circle-1 {
  animation: half-circle 3s infinite ease-in-out both;
}
.two-circles-traits #half-circle-2 {
  animation: half-circle 3s infinite 0.3s ease-in-out both;
}

.two-half-circle {
  position: absolute;
  width: 13vw;
  top: 2vh;
  right: 23vw;
  max-width: 200px;
}
.two-half-circle #shadow {
  animation: two-half-circle 3s infinite 0.3s ease-in-out both;
}

.double-circle {
  position: absolute;
  width: 13vw;
  top: 23vh;
  right: 8vw;
  max-width: 220px;
}
.double-circle #circle-rotation {
  transform-origin: 33% 67%;
  animation: circle-rotate 3s infinite 0.3s linear;
}

/*** 3 - À propos ***/
#a-propos {
  width: 80%;
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  position: relative;
}

.a-propos__img,
.a-propos__desc {
  width: 45%;
  position: relative;
}

.a-propos__img img {
  -webkit-mask-image: url("./assets/mask.svg");
  -webkit-mask-size: contain;
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-image: url("./assets/mask.svg");
  mask-size: contain;
  mask-position: center center;
  mask-repeat: no-repeat;
}
.a-propos__img:after {
  content: "";
  position: absolute;
  top: -15px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./assets/portrait-decoration.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.a-propos-cross {
  position: absolute;
  top: 25%;
  left: -90px;
  width: 75px;
}
.a-propos-cross #stroke {
  transform-origin: center;
  animation: cross-stroke 3s infinite ease-in-out both;
}
.a-propos-square {
  position: absolute;
  bottom: 20px;
  width: 150px;
  right: -130px;
  z-index: -1;
}
.a-propos-square .sens-horaire {
  transform-origin: center;
  animation: carre-sens-horaire 6s infinite ease-in-out both;
}
.a-propos-square .sens-anti-horaire {
  transform-origin: center;
  animation: carre-sens-anti-horaire 6s infinite ease-in-out both;
}

/*** 4 - Compétences ***/
#competences {
  width: 80%;
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 50px;
}

.competences__details {
  width: 33.3333333333%;
  position: relative;
  padding: 20px 20px 0 0;
}
.competences__cards {
  width: 45%;
  display: inline-block;
}
.competences__cards:last-child {
  padding-top: 50px;
}
.competences__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.competences__stack h3 {
  width: 100%;
  text-align: center;
  margin: 4rem auto;
}
.competences__stack--sous-titre {
  position: absolute;
  font-size: 1.8rem;
  top: -1.4rem;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 0 20px;
  background: white;
  color: #ffaf00;
}
.competences__stack-code, .competences__stack-design {
  width: 40%;
  border: #ffaf00 solid 2px;
  border-radius: 20px;
  padding: 30px;
  position: relative;
}
.competences__stack-code ul, .competences__stack-design ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}
.competences__stack-code li, .competences__stack-design li {
  width: 23%;
  text-align: center;
  margin: 10px;
}
.competences__stack img {
  height: 36px;
}
.competences__stack svg {
  font-size: 3.6rem;
}
.competences__stack svg[data-icon=html5] {
  color: #e56500;
}
.competences__stack svg[data-icon=css3-alt] {
  color: #007ee5;
}
.competences__stack svg[data-icon=sass] {
  color: #e500d2;
}
.competences__stack svg[data-icon=square-js] {
  color: #ffde00;
}
.competences__stack svg[data-icon=react] {
  color: #00e4ff;
}
.competences__items {
  position: relative;
  margin: 20px 10px;
}
.competences__items:hover .front, .competences__items:hover .back {
  transform: scaleX(-1);
}
.competences__items:hover .back {
  transform: scaleX(1);
  opacity: 1;
}
.competences__items img {
  width: 100px;
}
.competences__items .front, .competences__items .back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 150px;
  padding: 20px;
  background: #f5f5f5;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  border-radius: 20px;
}
.competences__items .back {
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  min-height: auto;
  background: #000000;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(-1);
  opacity: 0;
}
.competences__desc {
  display: none;
}
.competences-cercle {
  width: 120px;
  position: absolute;
  top: -30px;
  right: -30px;
}
.competences-cercle #circle-1, .competences-cercle #circle-2 {
  animation: competences-cercle 3s infinite 1s ease-in-out both;
}
.competences-cercle #circle-3 {
  animation: competences-cercle 3s infinite 0.5s ease-in-out both;
}
.competences-cercle #circle-4 {
  animation: competences-cercle 3s infinite ease-in-out both;
}

#competences__li {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

/*** 5 - Réalisations ***/
#realisations {
  width: 80%;
  max-width: 1180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.realisations__div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}
.realisations__items {
  width: 45%;
  margin: 20px auto;
}
.realisations__image {
  background-color: rgba(255, 175, 0, 0.1);
  width: 80%;
  z-index: 1;
  border-radius: 20px;
}
.realisations__details {
  background: #f5f5f5;
  margin-left: 20%;
  transform: translateY(-50px);
  z-index: 2;
  padding: 20px;
  border-radius: 20px;
  position: relative;
}
.realisations__git-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  height: 20px;
}
.realisations_filter {
  margin: 20px 0;
}

.card__titre {
  font-size: 2rem;
  color: #14213d;
  margin: 1.5rem auto;
}

/*** 6 - Contact ***/
#contact {
  background-color: #f5f5f5;
  background-image: url("./assets/geometric-shape/fond-contact.svg");
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
}

.contact {
  width: 80%;
  max-width: 1180px;
  padding: 50px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}
.contact__desc {
  width: 40%;
}
.contact .network {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.contact .network-items {
  width: 30px;
  margin: 0 10px;
}
.contact .network-items img[src*=youtube] {
  width: 80%;
}
.contact-fleche {
  width: 30%;
  transform: translateX(15vw);
  margin-top: 15px;
}
.contact-fleche .anim {
  animation: contact-fleche 3s infinite ease-in-out both;
  transform-origin: center;
}
.contact-fleche path:not(.anim) {
  animation: contact-fleche-ombre 3s infinite ease-in-out both;
  transform-origin: center;
}

/*** 7 - Footer ***/
.pied-page {
  background: #000000;
  color: white;
  padding: 20px 0;
  font-size: 1.4rem;
}
.pied-page p {
  margin: 0;
}
.pied-page--link,
.pied-page .pied-page--link:hover {
  color: #ffaf00;
}
.pied-page__container {
  width: 80%;
  max-width: 1180px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/*** 8 - Composants ***/
.button, .button--second, .button--accessibility {
  background: #ffaf00;
  color: #000000;
  padding: 10px 20px;
  border-radius: 10px;
  font-family: "Inter Tight", sans-serif;
  border: #ffaf00 solid 2px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: fit-content;
  font-size: 1.6rem;
  margin-right: 20px;
  z-index: 2;
  transition: transform 0.5s;
}
.button:after, .button--second:after, .button--accessibility:after {
  content: "";
  position: absolute;
  width: 120%;
  height: 150%;
  background-color: #000000;
  top: 50%;
  left: 0;
  transform: translate(-105%, -50%);
  transition: transform 0.5s;
  z-index: -1;
  border-radius: 0 40px 40px 0;
}
.button:hover, .button--second:hover, .button--accessibility:hover {
  color: #ffaf00;
}
.button:hover::after, .button--second:hover::after, .button--accessibility:hover::after {
  transform: translate(0, -50%);
}
.button--accessibility {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 50px;
  border: 2px solid;
}
.button--second {
  border: 2px solid;
}
.button--items {
  background: none;
  border: #ffaf00 3px solid;
  font-size: 1.8rem;
  line-height: 28px;
  font-family: "Inter Tight", sans-serif;
  padding: 0px 25px;
  margin: 0 10px;
  text-transform: capitalize;
  transition: 0.5s;
}
.button--items.active, .button--items:hover {
  background: #ffaf00;
  color: #000000;
}
.button--items.active:hover, .button--items:hover {
  border-color: #000000;
}
.button__scroll {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.button__scroll-ball {
  animation: scroll 2s ease-in-out infinite both;
}

input, textarea {
  background: white;
  margin: 10px 10px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ffaf00;
  font-family: "Inter Tight", sans-serif;
}
input::placeholder, textarea::placeholder {
  color: rgba(255, 175, 0, 0.5);
}

.contact__form {
  width: 55%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.contact__form-items {
  width: 100%;
  margin-bottom: 20px;
}
.contact__form .half-width {
  width: 50%;
}

.contact input, .contact textarea {
  width: calc(100% - 40px);
}

textarea {
  height: 80px;
}

.timeline {
  position: relative;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 4px;
  background: rgba(255, 175, 0, 0.4);
  transform: translateX(-50%);
}

.timeline__item {
  position: relative;
  margin-bottom: 30px;
}

.timeline__content {
  width: 40%;
}

.timeline__item:nth-child(even) .timeline__content {
  margin-left: auto;
}

.timeline__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background: #ffaf00;
  border: 6px solid white;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}

.timeline__content {
  padding: 30px;
  background: #f5f5f5;
  border-radius: 20px;
}
.timeline__content svg {
  font-size: 2rem;
  color: #ffaf00;
  margin-right: 20px;
}
.timeline__content-info-title svg, .timeline__content-info-date svg {
  display: inline-block;
  width: fit-content;
}
.timeline__content-info-title p, .timeline__content-info-date p {
  display: inline-block;
  width: calc(100% - 50px);
}
.content-skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.content-skills li {
  border-radius: 10px;
  border: #ffaf00 solid 3px;
  padding: 5px 10px;
  margin: 5px;
  text-align: center;
}

/*** 9 - Page des projets et réalisations***/
.intro,
.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.intro h2,
.details h2 {
  width: 100%;
}
.intro div,
.details div {
  width: 45%;
}

#project_cat {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
#project_cat:before {
  content: "";
  display: inline-block;
  width: 95px;
  height: 2px;
  background: #ffaf00;
  margin-right: 10px;
}
#project_cat span {
  padding-left: 7px;
}

#difficultes li, #caracteristiques li {
  position: relative;
  padding-left: 15px;
  margin-bottom: 15px;
}
#difficultes li::before, #caracteristiques li::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 7px;
  height: 7px;
  background-color: #ffaf00;
  border-radius: 5px;
}

.project_characteristics li {
  position: relative;
  padding-left: 60px;
  margin-bottom: 15px;
}
.project_characteristics li::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #ffaf00;
  border-radius: 5px;
}

/*** 10 - Responsive ***/
@media screen and (max-width: 980px) {
  /* Menu */
  .entete {
    min-height: 80px;
    height: auto;
    padding: 5px 0;
  }
  .entete_mobile-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    overflow: hidden;
  }
  .entete_mobile-menu span {
    width: 30px;
    height: 3px;
    background-color: #ffaf00;
    display: inline-block;
    border-radius: 5px;
    margin: 3px 0;
    transition: 0.5s;
  }
  .entete_mobile-menu.active span:first-child, .entete_mobile-menu.button--items:hover span:first-child {
    transform: rotate(45deg) translate(0px, -4px);
    transform-origin: top left;
  }
  .entete_mobile-menu.active span:nth-child(2), .entete_mobile-menu.button--items:hover span:nth-child(2) {
    transform: translateX(100%);
    transform-origin: center;
  }
  .entete_mobile-menu.active span:nth-child(3), .entete_mobile-menu.button--items:hover span:nth-child(3) {
    transform: rotate(-45deg) translate(1px, 1px);
    transform-origin: bottom left;
  }
  .entete_project {
    width: 100%;
    text-align: center;
  }
  .entete_nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 90px);
    background: rgba(0, 0, 0, 0.8);
    transform: translateX(100%);
    transition: 0.5s;
  }
  .entete_nav.active, .entete_nav.button--items:hover {
    transform: translateX(0);
  }
  .entete_items {
    width: fit-content;
    text-align: center;
    padding: 10px;
    margin: 10px 0;
    border-bottom: #ffaf00 2px solid;
  }
  .entete_project {
    justify-content: center;
    flex-direction: column;
  }
  #entete_page {
    margin: 10px 0;
  }
  /* Présentation */
  .presentation {
    height: calc(100vh - 100px);
  }
  .presentation_details {
    background: rgba(255, 255, 255, 0.5);
    border: #ffaf00 2px solid;
    padding: 20px;
  }
  .zigzag {
    top: 2vh;
    left: 2vw;
    width: 62vw;
  }
  .square {
    width: 23vw;
    top: 16vh;
    left: 19vw;
  }
  .square-points {
    width: 35vw;
    bottom: 2vh;
    right: 10vw;
  }
  .two-circles-traits {
    width: 40vw;
    top: auto;
    bottom: 0;
    transform: translate(0);
  }
  .two-half-circle {
    width: 23vw;
    top: 13vh;
    right: 20vw;
  }
  .double-circle {
    width: 21vw;
    top: 35vh;
    right: 0;
  }
  /* À propos */
  .a-propos__img {
    width: 100%;
  }
  .a-propos__desc {
    width: 100%;
    padding: 50px 0 90px;
  }
  .a-propos-cross {
    top: auto;
    bottom: 0;
    left: 20px;
  }
  .a-propos-square {
    top: -60px;
    right: 50px;
    z-index: 1;
  }
  /* Compétences */
  #competences__li {
    width: 100%;
  }
  .competences__cards {
    width: 50%;
  }
  .competences__title {
    font-weight: 700;
    margin-top: 0;
  }
  .competences__desc {
    display: inline-block;
    margin: 0;
  }
  .competences__details {
    width: 100%;
  }
  .competences__stack-code, .competences__stack-design {
    width: 100%;
    margin-bottom: 30px;
  }
  /* Réalisations */
  .realisations_filter {
    display: flex;
    flex-direction: column;
  }
  .button--filter {
    margin: 10px 10px 0;
  }
  .button--filter:first-child {
    margin: 0 10px;
  }
  .realisations__items {
    width: 100%;
  }
  /* Contact */
  #contact {
    background-position: right bottom;
    background-size: 50%;
  }
  .contact__desc {
    width: 100%;
  }
  .contact__form {
    width: 100%;
  }
  .contact .contact-fleche {
    width: 94px;
    transform: translateX(50px) rotate(90deg);
    margin: 15px 0;
  }
  /* Parcours */
  .timeline:before {
    left: 20px;
  }
  .timeline__icon {
    left: 20px;
  }
  .timeline__content {
    width: calc(100% - 140px);
    margin-left: auto;
  }
  /* Footer */
  .pied-page__container {
    justify-content: space-around;
    text-align: center;
  }
  /* Projets */
  #project_cat:before {
    width: 40px;
  }
  .intro div,
  .details div {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  /* Présentation */
  .presentation__network {
    display: none;
  }
  /* Compétences */
  .competences__cards {
    width: 100%;
  }
  .competences__cards:last-child {
    padding-top: 0;
    margin-top: -20px;
  }
  /* Réalisations */
  .realisations__image {
    width: 100%;
  }
  .realisations__details {
    transform: translateY(-20px);
    width: 80%;
    margin: auto;
  }
  /* Contact */
  #contact {
    background-size: 35%;
  }
}
@media screen and (min-width: 1400px) {
  .presentation_details {
    background: rgba(255, 255, 255, 0.2);
    border: #ffaf00 2px solid;
    padding: 20px;
  }
  .presentation__network-items {
    width: 45px;
  }
}
/*** 11 - Mode nuit ***/
body[data-dark-mode=true] {
  background-color: #14161a;
  color: #efebe0;
}
body[data-dark-mode=true] svg path[stroke="#FFAF00"] {
  stroke: #e79e00;
}
body[data-dark-mode=true] svg path[fill="#FFAF00"] {
  fill: #e79e00;
}
body[data-dark-mode=true] svg path[stroke="#010101"] {
  stroke: #fffcba;
}
body[data-dark-mode=true] svg path[fill="#010101"] {
  fill: #fffcba;
}
body[data-dark-mode=true] .titre {
  color: #dfd8c8;
}
body[data-dark-mode=true] .titre:after {
  background: #e79e00;
}
body[data-dark-mode=true] h2, body[data-dark-mode=true] h3 {
  color: #dfd8c8;
}
body[data-dark-mode=true] .button, body[data-dark-mode=true] .button--second,
body[data-dark-mode=true] .button--accessibility,
body[data-dark-mode=true] .button--filter.active,
body[data-dark-mode=true] .button--filter:hover {
  background: #e79e00;
}
body[data-dark-mode=true] .button--filter:not(.active):not(.button--items:hover) {
  color: #dfd8c8;
}
body[data-dark-mode=true] .night_mode_btn .jour_nuit-span:before {
  transform: translate(22px, -50%);
  background: #e79e00;
}
body[data-dark-mode=true] .realisations__image {
  background-color: rgba(255, 255, 255, 0.2);
}
body[data-dark-mode=true] .presentation {
  background: linear-gradient(0, #14161a, #222222 90%);
}
body[data-dark-mode=true] .front, body[data-dark-mode=true] .competences__items .back, .competences__items body[data-dark-mode=true] .back, body[data-dark-mode=true] .realisations__details, body[data-dark-mode=true] #contact {
  background-color: #111111;
}
@media screen and (max-width: 980px) {
  body[data-dark-mode=true] .presentation_details {
    background: rgba(255, 255, 255, 0.2);
  }
}
body[data-dark-mode=true] .competences__stack--sous-titre {
  background: #14161a;
}

/*** 12 - Animations ***/
/*Header*/
@keyframes scroll {
  from {
    transform: translateY(0);
    opacity: 0;
  }
  to {
    transform: translateY(45%);
    opacity: 1;
  }
}
/*Header présentation*/
@keyframes zigzag-black {
  0% {
    transform: translate(0);
  }
  33% {
    transform: translateX(20px);
  }
  66% {
    transform: translateX(-20px);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes zigzag-yellow {
  0% {
    transform: translate(0);
  }
  33% {
    transform: translateX(-20px);
  }
  66% {
    transform: translateX(20px);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes half-circle {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(50px, 50px);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes carre-sens-horaire-slow {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
@keyframes two-half-circle {
  0% {
    transform: translate(0);
  }
  35% {
    transform: translate(17px, -17px);
    filter: blur(0px);
  }
  50% {
    transform-origin: center;
    transform: translate(17px, -17px) scale(1.2);
    filter: blur(10px);
  }
  80% {
    transform-origin: center;
    transform: translate(17px, -17px) scale(1);
    filter: blur(0);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes circle-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
/*À propos*/
@keyframes cross-stroke {
  0% {
    transform: translate(0px) rotate(0);
  }
  20% {
    transform: translate(0px, 16px) rotate(0);
  }
  50% {
    transform: translate(-8px, 9px) rotate(90deg);
  }
  90% {
    transform: translate(-7px, -12px) rotate(90deg);
  }
  100% {
    transform: translate(0px) rotate(0);
  }
}
@keyframes carre-sens-horaire {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(3turn);
  }
  100% {
    transform: rotate(2turn);
  }
}
@keyframes carre-sens-anti-horaire {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-3turn);
  }
  100% {
    transform: rotate(-2turn);
  }
}
/*Compétences*/
@keyframes competences-cercle {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
/*Contact*/
@keyframes contact-fleche {
  0% {
    transform: translate(0) scale(1);
  }
  50% {
    transform: translate(20px, -10px) scale(1.2);
  }
  100% {
    transform: translate(0) scale(1);
  }
}
@keyframes contact-fleche-ombre {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

