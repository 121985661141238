.intro,
.details {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;

	h2 {
		width: 100%;
	}

	div {
		width: 45%;
	}
}

#project_cat {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;

	&:before {
		content: '';
		display: inline-block;
		width: 95px;
		height: 2px;
		background: $second-color;
		margin-right: 10px;
	}

	span {
		padding-left: 7px;
	}
}

#difficultes li, #caracteristiques li {
	position: relative;
	padding-left: 15px;
	margin-bottom: 15px;

	&::before {
		content: '';
		position: absolute;
		top: 7px;
		left: 0;
		width: 7px;
		height: 7px;
		background-color: $second-color;
		border-radius: 5px;
	}
}

.project_characteristics li {
	position: relative;
	padding-left: 60px;
	margin-bottom: 15px;

	&::before {
		content: '';
		position: absolute;
		top: 10px;
		left: 0;
		width: 50px;
		height: 2px;
		background-color: $second-color;
		border-radius: 5px;
	}
}
