	.close.button {
		position: absolute;
		top: 0;
		right: 0
	}

body.no-animation svg * {
	animation: none !important;
}

body.dys-font {
	p {
		font-family: $dys-font;
	}
	h1 {
		font-family: $dys-font;
	}
	h2 {
		font-family: $dys-font;
	}
	h3 {
		font-family: $dys-font;
	}
	li {
		font-family: $dys-font;
	}
	input {
		font-family: $dys-font;
	}
	textarea {
		font-family: $dys-font;
	}
}

body.partially-sighted-font * {
	p {
		font-family: $partially-sighted-font;
	}
	h1 {
		font-family: $partially-sighted-font;
	}
	h2 {
		font-family: $partially-sighted-font;
	}
	h3 {
		font-family: $partially-sighted-font;
	}
	li {
		font-family: $partially-sighted-font;
	}
	input {
		font-family: $partially-sighted-font;
	}
	textarea {
		font-family: $partially-sighted-font;
	}
}

.disable {
	color: #8b8b8b;
	text-decoration: line-through;
	input {
		pointer-events: none;
	}
}

.ReactModalPortal {
	position: fixed;
	z-index: 10;
}