html{
    font-size: 62.5%;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

main {
    overflow: hidden;
}

li,
ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

img, svg {
    width: 100%;
    height: auto;
}

svg {
    overflow: visible;
}

a,
a:hover {
    text-decoration: none;
    color: #000000;
}

section:not(.presentation) {
    margin: 80px auto;

    &:last-child {
        margin-bottom: 0;
    }
}

.corps_page--interieur {
    max-width: $max-width-screen;
    width: 80%;
    margin: auto;
    padding: 50px 0;

    &.error {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
    }
}