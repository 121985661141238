@media screen and (max-width: $tablet-breakpoint) {
	/* Menu */
	.entete {
		min-height: 80px;
		height: auto;
		padding: 5px 0;

		&_mobile-menu {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			cursor: pointer;
			overflow: hidden;

			span {
				width: 30px;
				height: 3px;
				background-color: #ffaf00;
				display: inline-block;
				border-radius: 5px;
				margin: 3px 0;
				transition: $transition-duration;
			}
			&.active {
				span:first-child {
					transform: rotate(45deg) translate(0px, -4px);
					transform-origin: top left;
				}
				span:nth-child(2) {
					transform: translateX(100%);
					transform-origin: center;
				}
				span:nth-child(3) {
					transform: rotate(-45deg) translate(1px, 1px);
					transform-origin: bottom left;
				}
			}
		}

		&_project {
			width: 100%;
			text-align: center;
		}

		&_nav {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			height: calc(100% - 90px);
			background: rgba(0, 0, 0, 0.8);
			transform: translateX(100%);
			transition: $transition-duration;

			&.active {
				transform: translateX(0);
			}
		}

		&_items {
			width: fit-content;
			text-align: center;
			padding: 10px;
			margin: 10px 0;
			border-bottom: $second-color 2px solid;
		}
	}

	.entete_project {
		justify-content: center;
		flex-direction: column;
	}

	#entete_page {
		margin: 10px 0;
	}

	/* Présentation */
	.presentation {
		height: calc(100vh - 100px);

		&_details {
			background: rgba(white, 0.5);
			border: $second-color 2px solid;
			padding: 20px;
		}
	}

	.zigzag {
		top: 2vh;
		left: 2vw;
		width: 62vw;
	}

	.square {
		width: 23vw;
		top: 16vh;
		left: 19vw;
	}

	.square-points {
		width: 35vw;
		bottom: 2vh;
		right: 10vw;
	}

	.two-circles-traits {
		width: 40vw;
		top: auto;
		bottom: 0;
		transform: translate(0);
	}

	.two-half-circle {
		width: 23vw;
		top: 13vh;
		right: 20vw;
	}

	.double-circle {
		width: 21vw;
		top: 35vh;
		right: 0;
	}

	/* À propos */
	.a-propos {
		&__img {
			width: 100%;
		}

		&__desc {
			width: 100%;
			padding: 50px 0 90px;
		}

		&-cross {
			top: auto;
			bottom: 0;
			left: 20px;
		}

		&-square {
			top: -60px;
			right: 50px;
			z-index: 1;
		}
	}

	/* Compétences */
	#competences__li {
		width: 100%;
	}

	.competences__cards {
		width: 50%;
	}

	.competences__title {
		font-weight: 700;
		margin-top: 0;
	}

	.competences__desc {
		display: inline-block;
		margin: 0;
	}

	.competences {
		&__details {
			width: 100%;
		}

		&__stack-code {
			width: 100%;
			margin-bottom: 30px;
		}
	}

	/* Réalisations */
	.realisations_filter {
		display: flex;
		flex-direction: column;
	}

	.button--filter {
		margin: 10px 10px 0;

		&:first-child {
			margin: 0 10px;
		}
	}

	.realisations__items {
		width: 100%;
	}

	/* Contact */
	#contact {
		background-position: right bottom;
		background-size: 50%;
	}

	.contact {
		&__desc {
			width: 100%;
		}

		&__form {
			width: 100%;
		}

		.contact-fleche {
			width: 94px;
			transform: translateX(50px) rotate(90deg);
			margin: 15px 0;
		}
	}

	/* Parcours */
	.timeline {
		&:before {
			left:20px
		}

		&__icon {
			left: 20px;
		}

		&__content {
			width: calc(100% - 140px);
			margin-left: auto;
		}
	}

	/* Footer */
	.pied-page__container {
		justify-content: space-around;
		text-align: center;
	}

	/* Projets */
	#project_cat:before {
		width: 40px;
	}

	.intro div,
	.details div {
		width: 100%;
	}
}

@media screen and (max-width: $phone-breakpoint) {
	/* Présentation */
	.presentation {
		&__network {
			display: none;
		}
	}

	/* Compétences */
	.competences__cards {
		width: 100%;

		&:last-child {
			padding-top: 0;
			margin-top: -20px;
		}
	}

	/* Réalisations */
	.realisations {
		&__image {
			width: 100%;
		}

		&__details {
			transform: translateY(-20px);
			width: 80%;
			margin: auto;
		}
	}

	/* Contact */
	#contact {
		background-size: 35%;
	}
}

@media screen and (min-width: $extra-large-breakpoint) {
	.presentation {
		&_details {
			background: rgba(white, 0.2);
			border: $second-color 2px solid;
			padding: 20px;
		}

		&__network-items {
			width: 45px;
		}
	}
}
