#competences {
	width: 80%;
	max-width: $max-width-screen;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	padding-top: 50px;
}

.competences {
	&__details {
		width: calc(100% / 3);
		position: relative;
		padding: 20px 20px 0 0;
	}

	&__cards {
		width: 45%;
		display: inline-block;

		&:last-child {
			padding-top: 50px;
		}
	}

	&__stack {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		h3 {
			width: 100%;
			text-align: center;
			margin: 4rem auto;
		}

		&--sous-titre {
			position: absolute;
			font-size: 1.8rem;
			top: -1.4rem;
			left: 50%;
			transform: translateX(-50%);
			margin: 0;
			padding: 0 20px;
			background: white;
			color: $second-color;
		}

		&-code {
			width: 40%;
			border: $second-color solid 2px;
			border-radius: 20px;
			padding: 30px;
			position: relative;

			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-evenly;
				align-items: flex-start;
			}

			li {
				width: 23%;
				text-align: center;
				margin: 10px;
			}
		}

		&-design {
			@extend .competences__stack-code;
		}

		img {
			height: 36px
		}

		svg {
			font-size: 3.6rem;

			&[data-icon="html5"] {
				color: #e56500
			}

			&[data-icon="css3-alt"] {
				color: #007ee5
			}

			&[data-icon="sass"] {
				color: #e500d2
			}

			&[data-icon="square-js"] {
				color: #ffde00;
			}

			&[data-icon="react"] {
				color: #00e4ff
			}
		}
	}

	&__items {
		position: relative;
		margin: 20px 10px;

		&:hover {
			.front {
				transform: scaleX(-1);
			}
			.back {
				transform: scaleX(1);
				opacity: 1;
			}
		}

		img {
			width: 100px;
		}

		.front {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			min-height: 150px;
			padding: 20px;
			background: $card-color;
			transition: $transition-duration;
			transition-timing-function: ease-in-out;
			border-radius: 20px;
		}

		.back {
			@extend .front;
			height: calc(100% - 40px);
			width: calc(100% - 40px);
			min-height: auto;
			background: $primary-color;
			color: white;
			position: absolute;
			top: 0;
			left: 0;
			transform: scaleX(-1);
			opacity: 0;
		}
	}

	&__desc {
		display: none;
	}

	&-cercle {
		width: 120px;
		position: absolute;
		top: -30px;
		right: -30px;
		
		#circle-1, #circle-2 {
			animation: competences-cercle 3s infinite 1s ease-in-out both;
		}
		
		#circle-3 {
			animation: competences-cercle 3s infinite 0.5s ease-in-out both;
		}
		
		#circle-4 {
			animation: competences-cercle 3s infinite ease-in-out both;
		}
	}
}

#competences__li {
	width: 60%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}
