/*Open Dyslexic*/
/*@font-face {
	font-family: 'Open Dyslexic';
	src: local('./font/open_dyslexic'),
		url('../../assets/font/open_dyslexic/OpenDyslexic-Regular.otf') format('opentype');
	font-weight: normal;
}
@font-face {
	font-family: 'Open Dyslexic';
	src: local('./font/open_dyslexic'),
		url('../../assets/font/open_dyslexic/OpenDyslexic-Bold.otf') format('opentype');
	font-weight: bold;
}
@font-face {
	font-family: 'Open Dyslexic';
	src: local('./font/open_dyslexic'),
		url('../../assets/font/open_dyslexic/OpenDyslexic-BoldItalic.otf') format('opentype');
	font-weight: bold;
	font-style: italic;
}*/

/*Luciole*/
/*@font-face {
	font-family: 'Luciole';
	src: local('./font/luciole'),
		url('../../assets/font/luciole/Luciole-Regular.ttf') format('truetype');
	font-weight: normal;
}
@font-face {
	font-family: 'Luciole';
	src: local('./font/luciole'),
		url('../../assets/font/luciole/Luciole-Regular-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'Luciole';
	src: local('./font/luciole'),
		url('../../assets/font/luciole/Luciole-Bold.ttf') format('truetype');
	font-weight: bold;
}
@font-face {
	font-family: 'Luciole';
	src: local('./font/luciole'),
		url('../../assets/font/luciole/Luciole-Bold-Italic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}*/

$primary-color: #000000;
$second-color: #ffaf00;
$third-color: #14213d;
$card-color: #f5f5f5;

$transition-duration: 0.5s;

$extra-large-breakpoint: 1400px;
$tablet-breakpoint: 980px;
$phone-breakpoint: 576px;

$max-width-screen: 1180px;

$font-family-title: 'Inter Tight', sans-serif;
$font-family-body: 'Inter Tight', sans-serif;
$dys-font: 'Inter Tight', sans-serif;
$partially-sighted-font: 'Inter Tight', sans-serif;

$size-font: 1;
$line-height: 1;
$spacing-word: 0;
body[data-size-font] {
  $size-font: attr(data-size-font)
}
body:has([data-height-line]) {
  $line-height: attr(data-height-line)
}
body[data-space-word] {
  $spacing-word: attr(data-space-word)
}