#a-propos {
	width: 80%;
	max-width: $max-width-screen;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding-top: 50px;
	position: relative;
}

.a-propos__img,
.a-propos__desc {
	width: 45%;
	position: relative;
}

.a-propos {
	&__img {
		img {
			-webkit-mask-image: url("./assets/mask.svg");
			-webkit-mask-size: contain;
			-webkit-mask-position: center center;
			-webkit-mask-repeat: no-repeat;
			mask-image: url("./assets/mask.svg");
			mask-size: contain;
			mask-position: center center;
			mask-repeat: no-repeat;
		}

		&:after {
			content: '';
			position: absolute;
			top: -15px;
			left: 0;
			width: 100%;
			height: 100%;
			background: url("./assets/portrait-decoration.svg");
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	&-cross {
		position: absolute;
		top: 25%;
		left: -90px;
		width: 75px;

		#stroke {
			transform-origin: center;
			animation: cross-stroke 3s infinite ease-in-out both;
		}
	}

	&-square {
		position: absolute;
		bottom: 20px;
		width: 150px;
		right: -130px;
		z-index: -1;

		.sens-horaire {
			transform-origin: center;
			animation: carre-sens-horaire 6s infinite ease-in-out both;
		}

		.sens-anti-horaire {
			transform-origin: center;
			animation: carre-sens-anti-horaire 6s infinite ease-in-out both;
		}
	}
}
