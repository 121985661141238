#contact {
    background-color: $card-color;
    background-image: url("./assets/geometric-shape/fond-contact.svg");
    background-position: right center;
    background-size: contain;
    background-repeat: no-repeat;
}

.contact {
    width: 80%;
    max-width: $max-width-screen;
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;

    &__desc {
        width: 40%;
    }

    .network {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        &-items {
            width: 30px;
            margin: 0 10px;

            img[src*="youtube"] {
                width: 80%;
            }
        }
    }

    &-fleche {
        width: 30%;
        transform: translateX(15vw);
        margin-top: 15px;

        .anim {
            animation: contact-fleche 3s infinite ease-in-out both;
            transform-origin: center;
        }

        path:not(.anim) {
            animation: contact-fleche-ombre 3s infinite ease-in-out both;
            transform-origin: center;
        }
    }
}