body {
	line-height: calc($line-height * 1.7em);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-title;
	font-weight: normal;
	margin: 3rem auto;
	line-height: 1.2em;
}

h1 {
	font-size: 3.6rem;
}

#entete_page {
	color: white;
	font-size: 2.8em;
	margin: 0
}

h2 {
	font-size: 2.6rem;
}

h3 {
	font-size: 2.4rem;
}

h4,
h5,
h6 {
	font-size: 1.6rem;
}

p, label, li {
	font-size: 1.6rem;
	font-family: $font-family-body;
	font-weight: 200;
	line-height: 1.5em;
}

a {
	&.link{
		background: linear-gradient(0deg, $second-color 0%, $second-color 65%, rgba(255,255,255,0) 65%);;
	}
}

.titre {
	color: $third-color;
	position: relative;
	width: fit-content;
	z-index: 2;

	&:after {
		content: "";
		display: block;
		background: $second-color;
		width: 110%;
		height: 2px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 2px;
		position: absolute;
		z-index: -1;
		bottom: 0;
	}
}
