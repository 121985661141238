body[data-dark-mode="true"] {
	background-color: #14161a;
	color: #efebe0;

	svg path[stroke='#FFAF00'] {
		stroke: #e79e00;
	}

	svg path[fill='#FFAF00'] {
		fill: #e79e00;
	}

	svg path[stroke='#010101'] {
		stroke: #fffcba;
	}

	svg path[fill='#010101'] {
		fill: #fffcba;
	}

	.titre {
		color: #dfd8c8;

		&:after {
			background: #e79e00;
		}
	}

	h2, h3 {
		color: #dfd8c8;
	}

	.button,
	.button--accessibility,
	.button--filter.active,
	.button--filter:hover {
		background: #e79e00;
	}

	.button--filter:not(.active) {
		color: #dfd8c8;
	}

	.night_mode_btn {
		.jour_nuit-span:before {
			transform: translate(22px, -50%);
			background: #e79e00;
		}
	}

	.realisations__image {
		background-color: rgba(255, 255, 255, 0.2);
	}

	.presentation {
		background: linear-gradient(0, #14161a, #222222 90%);
	}

	.front, .realisations__details, #contact {
		background-color: #111111;
	}

	@media screen and (max-width: 980px) {
		.presentation_details {
			background: rgba(255, 255, 255, 0.2);
		}
	}

	.competences__stack--sous-titre {
		background: #14161a;
	}
}
