.button {
    background: $second-color;
    color: $primary-color;
    padding: 10px 20px;
    border-radius: 10px;
    font-family: $font-family-title;
    border: $second-color solid 2px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: fit-content;
    font-size: 1.6rem;
    margin-right: 20px;
    z-index: 2;
    transition: transform $transition-duration;

    &:after {
        content: "";
        position: absolute;
        width: 120%;
        height: 150%;
        background-color: $primary-color;
        top: 50%;
        left: 0;
        transform: translate(-105%, -50%);
        transition: transform $transition-duration;
        z-index: -1;
        border-radius: 0 40px 40px 0;
    }

    &:hover {
        color: $second-color;

        &::after {
            transform: translate(0, -50%);
        }
    }

    &--accessibility {
        @extend .button;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 50px;
        border: 2px solid;
    }

    &--second {
        @extend .button;
        border: 2px solid;
    }

    &--items {
        background: none;
        border: $second-color 3px solid;
        font-size: 1.8rem;
        line-height: 28px;
        font-family: $font-family-title;
        padding: 0px 25px;
        margin: 0 10px;
        text-transform: capitalize;
        transition: $transition-duration;

        &.active {
            background: $second-color;
            color: $primary-color;
            
            &:hover {
                border-color: $primary-color;
            }
        }

        &:hover {
            @extend .active;
        }
    }

    &__scroll {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);

        &-ball {
            animation: scroll 2s ease-in-out infinite both;
        }
    }
}