/*Header*/
@keyframes scroll {
    from {
        transform: translateY(0);
        opacity: 0;
    }

    to {
        transform: translateY(45%);
        opacity: 1;
    }
}

/*Header présentation*/
@keyframes zigzag-black {
    0% {
        transform: translate(0);
    }

    33% {
        transform: translateX(20px);
    }

    66% {
        transform: translateX(-20px)
    }

    100% {
        transform: translate(0);
    }
}
@keyframes zigzag-yellow {
    0% {
        transform: translate(0);
    }

    33% {
        transform: translateX(-20px);
    }

    66% {
        transform: translateX(20px)
    }

    100% {
        transform: translate(0);
    }
}

@keyframes half-circle {
    0% {
        transform: translate(0);
    }

    50% {
        transform: translate(50px, 50px);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes carre-sens-horaire-slow {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(1turn)
    }
}

@keyframes two-half-circle {
    0% {
        transform: translate(0);
    }

    35% {
        transform: translate(17px, -17px);
        filter: blur(0px);
    }

    50% {
        transform-origin: center;
        transform: translate(17px, -17px) scale(1.2);
        filter: blur(10px);
    }

    80% {
        transform-origin: center;
        transform: translate(17px, -17px) scale(1);
        filter: blur(0);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes circle-rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

/*À propos*/
@keyframes cross-stroke {
    0% {
        transform: translate(0px) rotate(0);
    }

    20% {
        transform: translate(0px, 16px) rotate(0);
    }

    50% {
        transform: translate(-8px, 9px) rotate(90deg);
    }

    90% {
        transform: translate(-7px, -12px) rotate(90deg);
    }

    100% {
        transform: translate(0px) rotate(0);
    }
}

@keyframes carre-sens-horaire {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(3turn);
    }

    100% {
        transform: rotate(2turn)
    }
}

@keyframes carre-sens-anti-horaire {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(-3turn);
    }

    100% {
        transform: rotate(-2turn)
    }
}

/*Compétences*/
@keyframes competences-cercle {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0);
    }
}

/*Contact*/
@keyframes contact-fleche {
    0% {
        transform: translate(0) scale(1);
    }

    50% {
        transform: translate(20px, -10px) scale(1.2);
    }

    100% {
        transform: translate(0) scale(1);
    }
}

@keyframes contact-fleche-ombre {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}