.pied-page {
	background: $primary-color;
	color: white;
	padding: 20px 0;
	font-size: 1.4rem;

	p {
		margin: 0;
	}

	&--link,
	.pied-page--link:hover {
		color: $second-color;
	}

	&__container {
		width: 80%;
		max-width: $max-width-screen;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
}
