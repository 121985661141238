.timeline {
  position: relative;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 4px;
  background: rgba($second-color, 0.4);
  transform: translateX(-50%);
}

.timeline__item {
  position: relative;
  margin-bottom:30px
}

.timeline__content {
  width: 40%;
}

.timeline__item:nth-child(even) .timeline__content {
  margin-left: auto;
}

.timeline__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background: $second-color;
  border: 6px solid white;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}

.timeline__content {
  padding: 30px;
  background: $card-color;
  border-radius: 20px;

  svg {
    font-size: 2rem;
    color: $second-color;
    margin-right: 20px;
  }

  &-info-title {
    svg {
      display: inline-block;
      width: fit-content;
    }
    p {
      display: inline-block;
      width: calc(100% - 50px)
    }
  }

  &-info-date {
    @extend .timeline__content-info-title
  }
}

.content-skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  li {
    border-radius: 10px;
    border: $second-color solid 3px;
    padding: 5px 10px;
    margin: 5px;
    text-align: center;
  }
}

