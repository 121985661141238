input, textarea {
    background: white;
    margin: 10px 10px;
    padding: 10px;
    border: none;
    border-bottom: 2px solid $second-color;
    font-family: $font-family-title;
    
    &::placeholder{
        color: rgba($second-color, 0.5);
    }
}

.contact {
    &__form {
        width: 55%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        &-items {
            width: 100%;
            margin-bottom: 20px;
        }

        .half-width {
            width: 50%;
        }
    }
}
.contact input, .contact textarea{
    width: calc(100% - 40px);
}

textarea {
    height: 80px;
}