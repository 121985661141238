#realisations {
    width: 80%;
    max-width: $max-width-screen;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.realisations {
    &__div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 20px auto;
    }

    &__items {
        width: 45%;
        margin: 20px auto;
    }

    &__image {
        background-color: rgba($second-color, 0.1);
        width: 80%;
        z-index: 1;
        border-radius: 20px;
    }

    &__details {
        background: $card-color;
        margin-left: 20%;
        transform: translateY(-50px);
        z-index: 2;
        padding: 20px;
        border-radius: 20px;
        position: relative;
    }

    &__git-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        display: inline-block;
        height: 20px;
    }

    &_filter {
        margin: 20px 0;
    }
}

.card__titre {
    font-size: 2.0rem;
    color: $third-color;
    margin: 1.5rem auto;
}